import React, { useState } from "react";
import Button from "../components/button";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SEOAdditional from "../components/seo-additional";
import Cta from "../components/cta";
import Feature from "../components/feature";
import FeatureList from "../components/feature-list";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import NumberFormat from "react-number-format";
import Modal from "react-modal";
import { UilAngleDown, UilTimes } from "@iconscout/react-unicons";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function Icon({ icon, color }) {
    let uil = {
        chevronDown: UilAngleDown,
        times: UilTimes,
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color={color} />;
}

function Rupiah({ amount }) {
    return (
        <NumberFormat
            value={amount}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"Rp"}
        />
    );
}

function Fulfillment({ data }) {
    let d = data.contentfulPageFulfillment;

    const [itemsToShow, setItemsToShow] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <section className="py-4 md:py-16 md:-mt-24 bg-secondary dark:bg-gray-800">
                <div className="flex flex-col md:flex-row gap-y-6 w-full max-w-6xl mx-auto px-4 py-8 md:pt-16 md:pb-4">
                    <div className="w-full md:w-1/2 md:pr-10">
                        <Feature
                            label={d.label}
                            title={d.title}
                            hasImg={false}
                            padding="p-0"
                        />

                        <div className="font-sans text-xl">
                            {renderRichText(d.subtitle)}
                        </div>

                        <div className="hidden md:flex flex-wrap mt-6">
                            <Button
                                text={d.heroCta.text}
                                size="lg"
                                color={d.heroCta.color}
                                url={d.heroCta.url}
                                className="cta_hero"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <img src={`../images/${d.heroImage}`} />
                    </div>
                    <div className="flex md:hidden flex-wrap mt-6">
                        <Button
                            text={d.heroCta.text}
                            size="lg"
                            color={d.heroCta.color}
                            url={d.heroCta.url}
                            className="cta_hero"
                        />
                    </div>
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <div className="w-full flex flex-wrap justify-between md:gap-4 py-10">
                    {d.portfolio.featured.map((item) => (
                        <div
                            key={item.title}
                            className="flex flex-col md:flex-1 p-4 w-1/2 md:w-1/4"
                        >
                            <h4 className="text-4xl md:text-[60px] font-body leading-none mb-2 text-primary">
                                {item.highlight}
                            </h4>
                            <p className="text-gray-800 dark:text-gray-200 md:text-2xl font-display font-bold">
                                {item.title}
                            </p>
                            <p className="text-gray-600 dark:text-gray-400 text-sm md:text-lg">
                                {item.message}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <div className="flex flex-wrap gap-3 justify-center px-4 md:px-0">
                    {d.portfolio.brands.map((item) => (
                        <img
                            key={`brand-${item.name}`}
                            src={`../icons/brands/${item.logo}`}
                            className="h-[60px]"
                        />
                    ))}
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <CarouselProvider
                    isIntrinsicHeight={true}
                    visibleSlides={2}
                    totalSlides={d.testimony.length}
                    isPlaying={true}
                    lockOnWindowScroll={true}
                    className="px-4 md:px-8"
                >
                    <Slider classNameTray="space-x-11">
                        {d.testimony.map((item, i) => (
                            <Slide index={i} key={i}>
                                <div className="flex flex-col md:flex-row gap-6">
                                    <img
                                        src={`../images/testimonies/${item.image}`}
                                        alt={item.name}
                                        className="w-[240px] h-[240px] rounded-[20px] object-cover"
                                    />
                                    <div className="flex flex-col gap-3">
                                        <div>
                                            <div className="text-5xl font-sans font-medium text-primary">
                                                {item.highlight}
                                            </div>
                                            <div className="font-light text-gray-700 dark:text-gray-400">
                                                {item.highlight_title}
                                            </div>
                                        </div>
                                        <p className="text-[#212529] dark:text-gray-200 leading-relaxed">
                                            &quot;{parse(item.message)}&quot;
                                        </p>
                                        <div>
                                            <h5 className="text-sm font-medium dark:text-gray-400">
                                                {item.name}
                                            </h5>
                                            <a
                                                href={item.brand_link}
                                                className="text-primary underline text-sm italic"
                                            >
                                                {item.brand_name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        ))}
                    </Slider>

                    <div className="flex w-full mt-4">
                        <DotGroup>
                            {d.testimony.map((_, i) => (
                                <Dot
                                    key={i}
                                    className="w-2 h-2 rounded-full mx-2 bg-gray-700 dark:bg-gray-400 focus:outline-none opacity-25"
                                    slide={i}
                                />
                            ))}
                        </DotGroup>
                    </div>
                </CarouselProvider>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <Feature
                    title={d.features.title}
                    hasImg={false}
                    padding="py-2 px-4 md:px-8"
                />
                <div className="flex flex-wrap w-full">
                    {d.features.features.map((item, i) => (
                        <FeatureList
                            key={i}
                            props={item}
                            titleSize="text-xl"
                            titleMarginBottom="mb-3"
                            width="w-1/2 md:w-1/3"
                        />
                    ))}
                </div>

                <div className="flex justify-center w-full py-2 px-4 md:px-8">
                    <Button
                        text={d.featuresCta.text}
                        size="lg"
                        color={d.featuresCta.color}
                        url={d.featuresCta.url}
                    />
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <Feature
                    title={d.benefits.title}
                    hasImg={false}
                    padding="py-2 px-4 md:px-8"
                />

                <div className="px-4 md:px-8">
                    <div className="flex flex-col md:flex-row rounded-xl overflow-hidden">
                        <div className="bg-primary p-4 md:p-[60px]">
                            <div className="flex flex-col gap-8 justify-between bg-white dark:bg-gray-600 rounded-xl p-6 relative h-full">
                                <div className="flex flex-col gap-3">
                                    <div>
                                        <span className="text-xl font-display font-bold">
                                            Mulai
                                        </span>
                                        <br />
                                        <span className="font-bold font-display text-4xl md:text-5xl">
                                            <Rupiah
                                                amount={String(
                                                    d.benefits.price.amount
                                                )}
                                            />
                                        </span>
                                        <span className="text-2xl font-normal font-sans">
                                            /transaksi
                                        </span>
                                    </div>
                                    <div className="text-sm text-gray-600 dark:text-gray-200">
                                        {d.benefits.price.description}
                                    </div>
                                </div>
                                <div className="flex">
                                    <button
                                        type="button"
                                        className={`text-md px-6 py-3 text-primary dark:text-gray-200 hover:text-blue-100 border border-primary dark:border-gray-200 hover:bg-blue-800 w-full text-center font-medium tracking-wide rounded-md mr-2 transition-all ease-in duration-75 break-words`}
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        {d.benefits.price.cta.text}
                                    </button>
                                </div>

                                <div className="absolute top-0 right-0 -mt-5 -mr-6 md:-mt-7 md:-mr-8">
                                    <img
                                        src="../icons/star-yellow.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col justify-between p-4 md:p-[60px] bg-[#EBF2FF] dark:bg-gray-600">
                            <div>
                                <h2 className="text-2xl md:text-3xl mb-6 md:mb-[30px] leading-tight font-display text-gray-800 dark:text-gray-200 text-center">
                                    {d.benefits.benefits.title}
                                </h2>

                                <div className="flex flex-col flex-wrap md:flex-row">
                                    {d.benefits.benefits.content.map(
                                        (item, i) => (
                                            <div
                                                key={i}
                                                className="flex items-start gap-2 w-full md:w-1/2 mb-3"
                                            >
                                                <img src="../icons/check-blue.svg" />
                                                <div>{item}</div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row w-full">
                                <Button
                                    text={d.benefits.benefits.cta.text}
                                    size="lg"
                                    color={d.benefits.benefits.cta.color}
                                    url={d.benefits.benefits.cta.url}
                                    className="!w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <Feature
                    title={d.howToJoin.title}
                    hasImg={false}
                    padding="py-2 px-4 md:px-8"
                />

                <div className="flex flex-wrap w-full">
                    {d.howToJoin.step.map((item, i) => (
                        <FeatureList
                            key={i}
                            props={item}
                            imgWidth="120px"
                            imgHeight="120px"
                            width="w-full md:w-1/4"
                            titleSize="text-base"
                            titleMarginBottom="mb-4"
                            descriptionColor="text-[#212529] dark:text-gray-400"
                        />
                    ))}
                </div>

                <div className="flex w-full py-2 px-4 md:px-8">
                    <Button
                        text={d.howToJoin.cta.text}
                        size="lg"
                        color={d.howToJoin.cta.color}
                        url={d.howToJoin.cta.url}
                    />
                </div>
            </section>

            <section className="py-8 md:py-12 bg-gray-50 dark:bg-gray-800 px-4">
                <div className="w-full max-w-3xl mx-auto">
                    <Accordion>
                        {d.faq.map((item, i) => (
                            <AccordionItem
                                key={i}
                                className="p-4 border border-gray-100 dark:border-gray-800 shadow-lg rounded-lg mb-6 bg-white dark:bg-gray-700"
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton className="focus:outline-none">
                                        <h5 className="font-bold">
                                            {item.title}
                                        </h5>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className="mt-6 text-gray-500 dark:text-gray-400">
                                        {parse(item.description)}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>

            <Cta hideFAQ={true} />

            {d.seoAdditional.length ? (
                <section className="px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? "hidden" : ""}
                        />
                    ))}

                    {itemsToShow === 1 && d.seoAdditional.length > 1 ? (
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() =>
                                    setItemsToShow(d.seoAdditional.length)
                                }
                            >
                                <span>Selengkapnya</span>
                                <Icon icon={"chevronDown"} color="#718096" />
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </section>
            ) : (
                ""
            )}

            <Modal
                isOpen={isModalOpen}
                overlayClassName="fixed inset-0 flex flex-col justify-center items-center p-4"
                className="flex flex-col bg-white dark:bg-gray-800 w-full md:max-w-4xl max-h-full rounded-xl"
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = null)}
            >
                <div className="flex justify-between p-6">
                    <div className="font-bold font-body text-2xl dark:text-gray-200">
                        {d.feeDetails.title}
                    </div>
                    <button onClick={() => setIsModalOpen(false)}>
                        <Icon icon="times" color="#6B7280" />
                    </button>
                </div>
                <div className="mx-6 border-t border-2 border-gray-200 dark:border-gray-600"></div>
                <div className="h-full overflow-auto p-6">
                    <table className="table-fixed border border-gray-300 font-body w-full">
                        <thead>
                            <tr>
                                <th
                                    className="text-left border border-gray-300 dark:border-gray-700 p-3 text-sm dark:text-gray-200"
                                    colSpan={2}
                                >
                                    {d.feeDetails.label}
                                </th>
                                <th
                                    className="text-left border border-gray-300 dark:border-gray-700 p-3 text-xs font-normal dark:text-gray-200"
                                    colSpan={2}
                                >
                                    {d.feeDetails.description}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {d.feeDetails.items.map((item, i) =>
                                item.details.map((details, j) => (
                                    <tr key={`fee-row-${i}-${j}`}>
                                        {j === 0 && (
                                            <td
                                                className="text-left border border-gray-300 dark:border-gray-700 p-3 text-sm align-top dark:text-gray-200"
                                                rowSpan={item.details.length}
                                            >
                                                {item.title}
                                            </td>
                                        )}
                                        <>
                                            <td
                                                key={`fee-item-${j}`}
                                                className="text-left border border-gray-300 dark:border-gray-700 p-3 text-sm align-top dark:text-gray-200"
                                            >
                                                {details.title}
                                            </td>
                                            {details.content.map(
                                                (content, k) => (
                                                    <td
                                                        key={`fee-detail-${k}`}
                                                        className="text-left border border-gray-300 dark:border-gray-700 p-3 text-sm align-top dark:text-gray-200 h-0"
                                                        colSpan={
                                                            details.content
                                                                .length === 1
                                                                ? 2
                                                                : 1
                                                        }
                                                    >
                                                        {parse(content)}
                                                    </td>
                                                )
                                            )}
                                        </>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </Layout>
    );
}

Icon.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
};

Rupiah.propTypes = {
    amount: PropTypes.string,
};

Fulfillment.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageFulfillment {
            label
            title
            subtitle {
                raw
            }
            heroCta {
                text
                url
                color
            }
            heroImage
            portfolio {
                featured {
                    highlight
                    title
                    message
                }
                brands {
                    name
                    logo
                }
            }
            testimony {
                image
                highlight
                highlight_title
                message
                name
                brand_name
                brand_link
            }
            features {
                title
                features {
                    icon
                    title
                    description
                }
            }
            featuresCta {
                text
                url
                color
            }
            howToJoin {
                title
                step {
                    title
                    description
                    icon
                }
                cta {
                    text
                    url
                    color
                }
            }
            benefits {
                title
                price {
                    amount
                    description
                    cta {
                        text
                        color
                    }
                }
                benefits {
                    title
                    content
                    cta {
                        text
                        url
                        color
                    }
                }
            }
            feeDetails {
                title
                label
                description
                items {
                    title
                    details {
                        title
                        content
                    }
                }
            }
            faq {
                title
                description
            }
            seo {
                title
                description
                keywords
            }
            seoAdditional {
                titleTag
                title
                content
            }
        }
    }
`;

export default Fulfillment;
